<template>
    <div>
        <div class="modal fade" id="bankModal" tabindex="-1" aria-labelledby="bankModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="bankModalLabel" class="font-weight-bold">{{ModalTitle}}{{BankName}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CCardBody>

                            <CRow>
                                 <CCol sm="5">
                                    <label class="form-label font-weight-bold required">Nama Bank</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CInput id="BankName" v-model="BankName" class="font-weight-bold" />
                                    <label id="errorBankName" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                 <CCol sm="5">
                                    <label class="form-label font-weight-bold required">Nama Akun</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CInput id="AccountName" v-model="AccountName" class="font-weight-bold" />
                                    <label id="errorAccountName" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                 <CCol sm="5">
                                    <label class="form-label font-weight-bold required">Nomor Rekening</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="7">
                                    <CInput id="BankNumber" v-model="BankNumber" class="font-weight-bold" />
                                    <label id="errorBankNumber" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <br>
                            <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                            <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                        </CCardBody> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import bankService from '../Script/BankService.js';
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'BankForm',
    props: ['reload'],
    components: {
    },
    data: function () {
        return {
            ModalTitle : '',
            BankId:'',
            BankName: '',
            AccountName: '',
            BankNumber: '',
            Error: 0,
            SaveType: '',
        }
    },
    methods: {
        addClick(){  
            $(".form-error").css("display", "none");
            $(".save-button").show();
            this.ModalTitle = 'Add Bank'       
            this.BankId = '';
            this.BankName = ''
            this.BankNumber = '';
            this.AccountName = '';

            this.SaveType = 'Add';
            window.$('#bankModal').modal('show');
        },
        editClick(data, View){
            $(".form-error").css("display", "none");
            $(".save-button").show();
            
            this.ModalTitle = 'Edit Bank: '       
            this.BankId = data.id;
            this.BankName = data.bank_name;
            this.BankNumber = data.bank_number;
            this.AccountName = data.account_name;

            this.SaveType = 'Edit';
            
            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Bank: ';
            }

            window.$('#bankModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if(this.BankName == ''){
                this.errorShow('errorBankName');
            }
            if(this.AccountName == ''){
                this.errorShow('errorAccountName');
            }
            if(this.BankNumber == ''){
                this.errorShow('errorBankNumber');
            }
        },
        saveClick() {
            this.inputValidation();
            
            if(this.Error == 0){
                //Add
                const bankData = {
                    bank_name: this.BankName,
                    account_name: this.AccountName,
                    bank_number: this.BankNumber
                };
                this.$loading(true);
                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : bankData
                    }

                    bankService.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#bankModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                //Edit
                else if(this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : this.BankId,
                        data : bankData
                    }

                    bankService.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#bankModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    }
}
</script>
