<template>
    <div>
        <kendo-grid ref="grid"
                    :data-source="dataSource"
                    :pageable="true"
                    :filterable="true"
                    :sortable="true"
                    :columns=columns
                    :resizable="true"
                    >
        </kendo-grid>
    </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import bankService from '../Script/BankService.js';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'BankGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'deleteClick'],
    data(){
        return{
            dataSource: new kendo.data.DataSource({
                        page: 1,
                        pageSize: kendo_grid.default_grid_pagesize,
                        serverPaging: false,
                        transport: {
                            read: {
                                contentType: "application/json",
                                url: apiBaseUrl,
                                type: "POST",
                                data: function() {
                                    return { query: bankService.readQuery() };
                                },
                                beforeSend: function (req) {
                                    req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                                    req.setRequestHeader('Path', window.location.pathname);
                                }
                            },
                            parameterMap: function(options, operation) {
                                return  kendo.stringify({
                                    query: options.query,
                                    variables: options.variables
                                });
                            }
                        },
                        schema: {
                            data: function(response) {
                                if (response.data.GetBankList == null)
                                    return [];
                                else
                                    return response.data.GetBankList;
                            },
                            total: function(response) {
                                if (response.data.GetBankList == null)
                                    return 0;
                                else
                                    return response.data.GetBankList.length;
                            }
                        },
                }),
            columns:  [
                { title: "Action", width: 150, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "bank_name", title: "Nama Bank", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "account_name", title: "Nama Akun", headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "bank_number", title: "No. Rek", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "created_at", title: "Tanggal Dibuat", headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'dd-MM-yyyy') #", attributes: { style: "text-align:center;" }
                },
            ],
        }
    },
    mounted: function(){
        var deleteClick = this.$props.deleteClick;
        var editClick = this.$props.editClick;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;
        
        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            editClick(dataItem, true);
        })

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.id);
        })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionButton("Bank")
        }
    }
}
</script>

<style scoped>
</style>