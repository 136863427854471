import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class BankService {
    readQuery(){
        var query = `query{
            GetBankList{
                bank_name
                id
                account_name
                bank_number
                created_at
                last_update
            }
        }`;
        return query;
    }

    async deleteQuery(variables){
        const query = gql`mutation($id:Int!){
            DeleteBank(ID:$id)
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async addQuery(variables){
        const query = gql`mutation($data:NewBankList!){
            CreateBank(New:$data)
        }`
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async editQuery(variables){
        const query = gql`mutation($id:Int!,$data:NewBankList!){
            UpdateBank(ID:$id,New:$data)
        }`
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}


export default new BankService();